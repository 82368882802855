<template>
	<div>
		<pui-modal-dialog-form
			:titleText="$t('cargo.requestClearanceAction')"
			:modelName="modelName"
			:dialogName="dialogNameRequestClearance"
			:widthDialog="800"
			:onOk="onOkActionsRequestRejectConfirmAndReject"
			:onShow="onShowRequestClearance"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-field-set :title="$t('cargo.operation')">
							<v-layout wrap style="margin-top: 15px; margin-bottom: 15px">
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-unloading-${modelName}`"
										:label="$t('cargo.unloading')"
										v-model="checkboxGroup.unloading"
										true-value="true"
										false-value="false"
										:disabled="unloadingDisabled"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-loading-${modelName}`"
										:label="$t('cargo.loading')"
										v-model="checkboxGroup.loading"
										true-value="true"
										false-value="false"
										:disabled="loadingDisabled"
									></pui-checkbox
								></v-flex>
							</v-layout>
						</pui-field-set>
						<v-flex xs12 md12 style="margin-top: 15px">
							<pui-text-area
								:id="`cargo-observations`"
								v-model="data.modalData.remarks"
								:label="$t('cargo.requestclearancetextbox')"
								toplabel
								maxlength="256"
							></pui-text-area
						></v-flex>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('cargo.rejectDocumentation')"
			:modelName="modelName"
			:dialogName="dialogNameRejectDocumentation"
			:widthDialog="800"
			:onOk="onOkActionsRequestRejectConfirmAndReject"
			:onShow="onShowRejectDocumentation"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-field-set :title="$t('cargo.operation')">
							<v-layout wrap style="margin-top: 15px; margin-bottom: 15px">
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-unloading-${modelName}`"
										:label="$t('cargo.unloading')"
										v-model="checkboxGroup.unloading"
										true-value="true"
										false-value="false"
										:disabled="unloadingDisabled"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-loading-${modelName}`"
										:label="$t('cargo.loading')"
										v-model="checkboxGroup.loading"
										true-value="true"
										false-value="false"
										:disabled="loadingDisabled"
									></pui-checkbox
								></v-flex>
							</v-layout>
						</pui-field-set>
						<v-flex xs12 md12 style="margin-top: 15px">
							<pui-text-area
								:id="`cargo-rejectdocumenttextbox`"
								v-model="data.modalData.remarks"
								:label="$t('cargo.rejectdocumenttextbox')"
								toplabel
								maxlength="256"
							></pui-text-area
						></v-flex>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('cargo.confirmClearance')"
			:modelName="modelName"
			:dialogName="dialogNameConfirmClearance"
			:widthDialog="800"
			:onOk="onOkActionsRequestRejectConfirmAndReject"
			:onShow="onShowConfirmClearance"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-field-set :title="$t('cargo.operation')">
							<v-layout wrap style="margin-top: 15px; margin-bottom: 15px">
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-unloading-${modelName}`"
										:label="$t('cargo.unloading')"
										v-model="checkboxGroup.unloading"
										true-value="true"
										false-value="false"
										:disabled="unloadingDisabled"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-loading-${modelName}`"
										:label="$t('cargo.loading')"
										v-model="checkboxGroup.loading"
										true-value="true"
										false-value="false"
										:disabled="loadingDisabled"
									></pui-checkbox
								></v-flex>
							</v-layout>
						</pui-field-set>
						<v-layout wrap style="margin-top: 15px">
							<v-col class="pt-6" cols="5">
								<label>{{ $t('cargo.selectcodes') }}</label>
							</v-col>
							<v-col class="pt-6" cols="3">
								<pui-select
									:id="`selectyesno-cargo`"
									v-model="selectedYesNo"
									:modelFormMapping="{ valor: 'value' }"
									reactive
									leftlabel
									modelName="vlupyesnoquestions"
									:itemsToSelect="[{ valor: selectedYesNo.value }]"
									:itemValue="['valor']"
									:itemText="(item) => `${item.opcion}`"
								></pui-select>
							</v-col>
						</v-layout>

						<v-flex xs12 md12>
							<pui-text-area
								:id="`cargo-confirmclearancetextbox`"
								v-model="data.modalData.remarks"
								:label="$t('cargo.confirmclearancetextbox')"
								toplabel
								maxlength="256"
							></pui-text-area
						></v-flex>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('cargo.rejectClearance')"
			:modelName="modelName"
			:dialogName="dialogNamerejectClearance"
			:widthDialog="800"
			:onOk="onOkActionsRequestRejectConfirmAndReject"
			:onShow="onShowrejectClearance"
		>
			<template slot="message" slot-scope="data">
				<v-layout wrap class="justify-center">
					<v-flex xs12>
						<pui-field-set :title="$t('cargo.operation')">
							<v-layout wrap style="margin-top: 15px; margin-bottom: 15px">
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-unloading-${modelName}`"
										:label="$t('cargo.unloading')"
										v-model="checkboxGroup.unloading"
										true-value="true"
										false-value="false"
										:disabled="unloadingDisabled"
									></pui-checkbox
								></v-flex>
								<v-flex xs12 md12 lg3 x3
									><pui-checkbox
										:id="`checkbox-loading-${modelName}`"
										:label="$t('cargo.loading')"
										v-model="checkboxGroup.loading"
										true-value="true"
										false-value="false"
										:disabled="loadingDisabled"
									></pui-checkbox
								></v-flex>
							</v-layout>
						</pui-field-set>
						<v-flex xs12 md12 style="margin-top: 15px">
							<pui-text-area
								:id="`cargo-rejectClearancetextbox`"
								v-model="data.modalData.remarks"
								:label="$t('cargo.rejectClearancetextbox')"
								toplabel
								required
								maxlength="256"
							></pui-text-area
						></v-flex>
					</v-flex>
				</v-layout>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'georepreport-modals',
	data() {
		return {
			dialogNameRequestClearance: 'requestClearance',
			dialogNameRejectDocumentation: 'rejectDocumentation',
			dialogNameConfirmClearance: 'confirmClearance',
			dialogNamerejectClearance: 'rejectClearance',
			dialognameConfirmRejectClearance: 'confirmrejectclearance',
			checkboxGroup: {
				loading: false,
				unloading: false
			},
			actionClearance: false,
			actionRejectDoc: false,
			actionConfirmClearance: false,
			actionRejectClearance: false,
			loadingDisabled: false,
			unloadingDisabled: false,
			selectedYesNo: {
				value: 0
			}
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onShowRequestClearance(modalData) {
			this.actionRejectDoc = false;
			this.actionClearance = true;
			this.actionConfirmClearance = false;
			this.actionRejectClearance = false;
			this.selectedYesNo.value = null;
			this.disableCheckbox(modalData, 1);
		},
		onShowRejectDocumentation(modalData) {
			this.actionRejectDoc = true;
			this.actionClearance = false;
			this.actionConfirmClearance = false;
			this.actionRejectClearance = false;
			this.selectedYesNo.value = null;
			this.disableCheckbox(modalData, 2);
		},
		onShowConfirmClearance(modalData) {
			this.actionRejectDoc = false;
			this.actionClearance = false;
			this.actionConfirmClearance = true;
			this.actionRejectClearance = false;
			this.selectedYesNo.value = null;
			this.disableCheckbox(modalData, 3);
		},
		onShowrejectClearance(modalData) {
			this.actionRejectDoc = false;
			this.actionClearance = false;
			this.actionConfirmClearance = false;
			this.actionRejectClearance = true;
			this.selectedYesNo.value = null;
			this.disableCheckbox(modalData, 4);
		},
		onOkActionsRequestRejectConfirmAndReject(modalData) {
			if (this.checkboxGroup.loading == 'false' || this.checkboxGroup.loading == false) this.checkboxGroup.loading = false;
			if (this.checkboxGroup.unloading == 'false' || this.checkboxGroup.unloading == false) this.checkboxGroup.unloading = false;

			if (!this.checkboxGroup.loading && !this.checkboxGroup.unloading) {
				this.$puiNotify.error(this.$puiI18n.t('cargo.checkboxerror'), this.$puiI18n.t('cargo.checkboxtitleerror'));
			} else {
				return new Promise((resolve) => {
					this.$puiRequests.getRequest(
						this.$store.getters.getModelByName('cargo').url.actionsRequestAndReject,
						{
							model: this.modelName,
							cargoclearanceid: modalData.headerPk.cargoclearanceid,
							checkloading: this.checkboxGroup.loading,
							checkunloading: this.checkboxGroup.unloading,
							remarks: modalData.remarks,
							actionRequest: this.actionClearance,
							actionReject: this.actionRejectDoc,
							actionConfirmClearance: this.actionConfirmClearance,
							actionRejectClearance: this.actionRejectClearance,
							selectYesNo: this.selectedYesNo.value
						},
						() => {
							this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
							this.$puiEvents.$emit('refreshForm-action-running-ended-' + this.modelName);
							if (this.actionClearance)
								this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), this.$puiI18n.t('cargo.requestClearanceAction'));
							if (this.actionRejectDoc)
								this.$puiNotify.success(
									this.$puiI18n.t('puiaction.notifySuccess'),
									this.$puiI18n.t('cargo.rejectDocumentationAction')
								);
							if (this.actionConfirmClearance)
								this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), this.$puiI18n.t('cargo.confirmClearanceAction'));
							if (this.actionRejectClearance)
								this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), this.$puiI18n.t('cargo.actionRejectClearance'));
							resolve(true);
							this.checkboxGroup.loading = false;
							this.checkboxGroup.unloading = false;
							this.$puiEvents.$emit('onPuiRouter-sameModelAndComponent-refreshRouter');
						},
						(e) => {
							let message = this.$puiI18n.t('puiaction.notifyError');
							if (e.response && e.response.data) {
								message = e.response.data.message;
							}
							if (this.actionClearance) this.$puiNotify.error(message, this.$puiI18n.t('cargo.requestClearanceAction', 0, true));
							if (this.actionRejectDoc) this.$puiNotify.error(message, this.$puiI18n.t('cargo.rejectDocumentationAction', 0, true));
							if (this.actionConfirmClearance) this.$puiNotify.error(message, this.$puiI18n.t('cargo.confirmClearanceAction', 0, true));
							if (this.actionRejectClearance) this.$puiNotify.error(message, this.$puiI18n.t('cargo.actionRejectClearance', 0, true));
							resolve(false);
							this.checkboxGroup.loading = false;
							this.checkboxGroup.unloading = false;
							this.$puiEvents.$emit('onPuiRouter-sameModelAndComponent-refreshRouter');
						}
					);
				});
			}
		},
		disableCheckbox(modalData, actionNum) {
			this.checkboxGroup.loading = false;
			this.checkboxGroup.unloading = false;
			this.loadingDisabled = false;
			this.unloadingDisabled = false;

			let loading = modalData.registries.loadingstatus;
			let unloading = modalData.registries.unloadingstatus;

			if (actionNum == 1) {
				if (
					loading == 'Documentation ready' ||
					loading == 'Cleared' ||
					loading == 'Rejected' ||
					loading == 'Clearance requested' ||
					loading == 'დოკუმენტაცია მზად არის განსახილველად' ||
					loading == 'ნებართვა გაცემულია' ||
					loading == 'ნებართვა უარყოფილია' ||
					loading == 'მოთხოვნა ნებართვაზე გაგზავნილია'
				) {
					this.loadingDisabled = true;
				}
				if (
					unloading == 'Documentation ready' ||
					unloading == 'Cleared' ||
					unloading == 'Rejected' ||
					unloading == 'Clearance requested' ||
					unloading == 'დოკუმენტაცია მზად არის განსახილველად' ||
					unloading == 'ნებართვა გაცემულია' ||
					unloading == 'ნებართვა უარყოფილია' ||
					unloading == 'მოთხოვნა ნებართვაზე გაგზავნილია'
				) {
					this.unloadingDisabled = true;
				}
			}
			if (actionNum == 2 || actionNum == 3) {
				if (
					loading == 'Documentation pending' ||
					loading == 'Documentation ready' ||
					loading == 'Documentation rejected' ||
					loading == 'Cleared' ||
					loading == 'Rejected' ||
					loading == 'Partially cleared' ||
					loading == 'დოკუმენტაცია მოლოდინის რეჟიმშია' ||
					loading == 'დოკუმენტაცია მზად არის განსახილველად' ||
					loading == 'დოკუმენტაცია უარყოფილია' ||
					loading == 'ნებართვა გაცემულია' ||
					loading == 'ნებართვა უარყოფილია' ||
					loading == 'ნაწილობრივი ნებართვა გაცემულია'
				) {
					this.loadingDisabled = true;
				}
				if (
					unloading == 'Documentation pending' ||
					unloading == 'Documentation ready' ||
					unloading == 'Documentation rejected' ||
					unloading == 'Cleared' ||
					unloading == 'Rejected' ||
					unloading == 'Partially cleared' ||
					unloading == 'დოკუმენტაცია მოლოდინის რეჟიმშია' ||
					unloading == 'დოკუმენტაცია მზად არის განსახილველად' ||
					unloading == 'დოკუმენტაცია უარყოფილია' ||
					unloading == 'ნებართვა გაცემულია' ||
					unloading == 'ნებართვა უარყოფილია' ||
					unloading == 'ნაწილობრივი ნებართვა გაცემულია'
				) {
					this.unloadingDisabled = true;
				}
			}
			if (actionNum == 4) {
				if (loading == 'Rejected' || loading == 'ნებართვა უარყოფილია') {
					this.loadingDisabled = true;
				}
				if (unloading == 'Rejected' || unloading == 'ნებართვა უარყოფილია') {
					this.unloadingDisabled = true;
				}
			}
		}
	}
};
</script>
